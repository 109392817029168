<template>
  <section class="intro-x">
    <div class="font-bold text-xl my-3">
      Informes
    </div>
    <form @submit.prevent="onFilter">
      <div class="w-full box p-4">
        <!-- Tipo -->
        <div class="flex flex-col lg:flex-row lg:items-center mb-2">
          <label for="fechaInicio" class="w-1/3">Tipo</label>
          <select v-model="getFilter.tipo" id="tipo" class="form-control">
            <option value="">--Seleccionar--</option>
            <option value="1">Solicitudes</option>
            <option value="2">Pedidos</option>
          </select>
        </div>
        <!-- fecha inicio -->
        <div class="flex flex-col lg:flex-row lg:items-center mb-2">
          <label for="fechaInicio" class="w-1/3">Fecha inicio</label>
          <input
          id="fechaInicio"
          type="date"
          v-model="getFilter.fechaInicio"
          class="form-control"
          >
        </div>
        <!-- fecha fin   -->
        <div class="flex flex-col lg:flex-row lg:items-center  mb-2">
          <label for="fechaFin" class="w-1/3">Fecha fin</label>
          <input
          id="fechaFin"
          type="date"
          v-model="getFilter.fechaFin"
          class="form-control"
          >
        </div>
        <!-- sedes -->
        <div v-if="getFilter.tipo === '1'" class="mb-2 flex flex-col lg:flex-row lg:items-center">
          <label for="branchOfficeId" class="w-1/3">Ub. <br> Sedes</label>
          <div class="w-full overflow-hidden">
            <MultiSelect
            v-model="getFilter.branchOfficeIds"
            :options="getBranchOffices"
            :filter="true"
            placeholder="Seleccionar"
            optionLabel="name"
            class="w-full overflow-hidden"
            />
            <div
            class="w-full mt-2 overflow-y-auto overflow-x-hidden "
            style="max-height:150px;"
            >
              <span
              v-for="(row,m) in getFilter.branchOfficeIds"
              :key="m"
              class="border bg-gray-100 px-2 py-1 mr-1 mb-1 rounded-lg "
              style="display:inline-block;"
              >
                {{row.name}}
              </span>
            </div>
          </div>
        </div>
        <!-- Areas -->
        <div v-if="getFilter.tipo === '1'" class="mb-2 flex flex-col lg:flex-row lg:items-center">
          <label for="branchOfficeId" class="w-1/3">Ub. <br> Areas</label>
          <div class="w-full overflow-hidden">
            <MultiSelect
            v-model="getFilter.areaIds"
            :options="getAreas"
            :filter="true"
            placeholder="Seleccionar"
            optionLabel="name"
            class="w-full overflow-hidden"
            />
            <div
            class="w-full mt-2 overflow-y-auto overflow-x-hidden "
            style="max-height:150px;"
            >
              <span
              v-for="(row,m) in getFilter.areaIds"
              :key="m"
              class="border bg-gray-100 px-2 py-1 mr-1 mb-1 rounded-lg "
              style="display:inline-block;"
              >
                {{row.name}}
              </span>
            </div>
          </div>
        </div>
        <!-- Categorias -->
        <div v-if="getFilter.tipo === '1'" class="mb-2 flex flex-col lg:flex-row lg:items-center">
          <label for="branchOfficeId" class="w-1/3">Categorias</label>
          <div class="w-full overflow-hidden">
            <MultiSelect
            v-model="getFilter.categoriasIds"
            :options="getCategorias"
            :filter="true"
            placeholder="Seleccionar"
            optionLabel="name"
            class="w-full overflow-hidden"
            />
            <div
            class="w-full mt-2 overflow-y-auto overflow-x-hidden "
            style="max-height:150px;"
            >
              <span
              v-for="(row,m) in getFilter.categorias"
              :key="m"
              class="border bg-gray-100 px-2 py-1 mr-1 mb-1 rounded-lg "
              style="display:inline-block;"
              >
                {{row.name}}
              </span>
            </div>
          </div>
        </div>
        <!-- Proveedores -->
        <div v-if="getFilter.tipo === '2'" class="mb-2 flex flex-col lg:flex-row lg:items-center">
          <label for="branchOfficeId" class="w-1/3">Proveedores</label>
          <div class="w-full overflow-hidden">
            <SelectProveedores
            v-model="getFilter.proveedorId"
            class="w-full"
            />
          </div>
        </div>
        <!-- botones -->
        <div v-if="$can('pharmasan.administrativa.compras-administrativas.informes.access')" class="w-full my-5">
          <button
          type="button"
          class="btn btn-primary"
          @click="onFilter"
          >
            Generar
          </button>
          <button
          type="button"
          class="btn btn-warning ml-2"
          @click="onFilterClear"
          >
            Limpiar
          </button>
        </div>
      </div>
    </form>
  </section>

</template>

<script>
import { onMounted } from 'vue'
import useInforme from './useInformes'
import MultiSelect from 'primevue/multiselect'
import SelectProveedores from '../../../../../../components/select/proveedores'
export default {
  name: 'Informes',
  components: {
    MultiSelect,
    SelectProveedores
  },
  setup () {
    const {
      getFilter,
      getBranchOffices,
      getAreas,
      getCategorias,
      fetchSelects,
      onFilter,
      onFilterClear
    } = useInforme()

    onMounted(fetchSelects)

    return {
      getBranchOffices,
      getFilter,
      getAreas,
      getCategorias,
      onFilter,
      onFilterClear
    }
  }
}
</script>
