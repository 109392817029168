import { computed, ref } from 'vue'
import { messageConfirm, messageWarning } from '../../../../../../libs/mensajes'
import areasListar from '../../../services/areasListar'
import brancheOfficesListar from '../../../services/brancheOfficesListar'
import categoriasListar from '../../../services/categoriasListar'
import informeFiltrarService from '../../../services/informeFiltrarService'
import dayjs from 'dayjs'

const useInforme = () => {
  const _filter = ref({
    tipo: '1',
    fechaInicio: '',
    fechaFin: '',
    branchOfficeIds: [],
    areaIds: [],
    categoriasIds: [],
    proveedorId: ''
  })
  const _branchOffices = ref([])
  const _areas = ref([])
  const _categorias = ref([])

  const getBranchOffices = computed(() => _branchOffices.value)
  const getFilter = computed(() => _filter.value)
  const getCategorias = computed(() => _categorias.value)
  const getAreas = computed(() => _areas.value)

  const fetchBranchOffices = () => {
    return brancheOfficesListar().then(({ data }) => {
      _branchOffices.value = data
      return data
    }).catch(err => {
      console.error(err.message)
    })
  }

  const fetchCategorias = () => {
    return categoriasListar().then(({ data }) => {
      _categorias.value = data
      return data
    }).catch(err => {
      console.error(err.message)
    })
  }

  const fetchAreas = () => {
    return areasListar().then(({ data }) => {
      _areas.value = data
      return data
    }).catch(err => {
      console.error(err.message)
    })
  }
  const fetchSelects = () => {
    return Promise.all([
      fetchBranchOffices(),
      fetchAreas(),
      fetchCategorias()
    ])
  }
  const _hasErrors = () => {
    if (!_filter.value.tipo) {
      messageWarning('Es necesario seleccionar un tipo de informe antes de continuar')
      return true
    }
    const fechaInicio = dayjs(_filter.value.fechaInicio)
    const fechaFin = dayjs(_filter.value.fechaFin)
    if (fechaInicio.isAfter(fechaFin)) {
      messageWarning('La fecha final debe ser mayor que la fecha inicial')
      return true
    }
    return false
  }
  const onFilter = () => {
    if (_hasErrors()) return false

    const {
      fechaInicio,
      fechaFin,
      branchOfficeIds,
      areaIds,
      categoriasIds,
      tipo,
      proveedorId
    } = _filter.value
    const sedes = branchOfficeIds.map(a => a.id).join(',')
    const areas = areaIds.map(a => a.id).join(',')
    const categorias = categoriasIds.map(a => a.id).join(',')
    const endPoint = informeFiltrarService({
      fechaInicio,
      fechaFin,
      sedes,
      areas,
      categorias,
      tipo,
      proveedorId
    })
    messageConfirm('Este informe se abrira en una nueva pestana. sea paciente podria tardar varios minutos.').then(({ isConfirmed }) => {
      if (isConfirmed) {
        window.open(endPoint, '_blank')
      }
    })
  }
  const onFilterClear = () => {
    _filter.value.areaIds = []
    _filter.value.branchOfficeIds = []
    _filter.value.categoriasIds = []
    _filter.value.fechaFin = ''
    _filter.value.fechaInicio = ''
  }

  return {
    getFilter,
    getBranchOffices,
    getAreas,
    getCategorias,
    fetchBranchOffices,
    fetchAreas,
    fetchCategorias,
    fetchSelects,
    onFilter,
    onFilterClear
  }
}

export default useInforme
